<template>
  <div class="admin-guidance-create-help-video">
    <loading-screen :is-loading="isSaving"></loading-screen>

    <!-- Page Header -->
    <div class="page-header">
      <h1 class="page-title">Create Help Video</h1>
      <div class="actions">
        <a-button
          class="button-margin-left btn-rounded"
          icon="arrow-left"
          size="large"
          type="primary"
          @click.prevent="goBack"
          >Back</a-button
        >
      </div>
    </div>
    <!-- / Page Header -->

    <!-- Is Loading -->
    <div v-if="isLoading" class="loader">
      <a-spin></a-spin>
    </div>
    <!-- / Is Loading -->

    <div v-if="!isLoading">
      <p style="font-weight: 500">
        You can upload a single video or both landscape and portrait versions,
        or embed from the web.
      </p>
    </div>

    <!-- Basic Details -->
    <div v-if="!isLoading" class="form">
      <div class="top-row">
        <div class="left">
          <a-form-item label="Thumbnail">
            <a-upload
              :multiple="false"
              :file-list="fileList"
              :before-upload="beforeUpload"
              :remove="handleRemove"
            >
              <a-button>
                <a-icon type="upload"></a-icon>
                Click to Upload
              </a-button>
            </a-upload>
          </a-form-item>
        </div>
        <div class="right">
          <a-form-item label="Title">
            <a-input
              size="large"
              v-model="helpVideoLocal.displayName"
            ></a-input>
          </a-form-item>
        </div>
      </div>

      <a-form-item label="Visibility">
        <a-radio-group v-model="helpVideoLocal.scope">
          <a-radio :value="1">Shown to organisation</a-radio>
          <!-- <a-radio :value="2">Shown to whole tenant</a-radio> -->
          <a-radio :value="3">Hidden from lists</a-radio>
        </a-radio-group>
      </a-form-item>

      <a-form-item label="Display Order">
        <a-radio-group v-model="helpVideoLocal.rank">
          <a-radio v-for="(rank, i) in ranks" :key="i" :value="rank.rank">{{
            rank.label
          }}</a-radio>
        </a-radio-group>
      </a-form-item>
    </div>
    <!-- / Basic Details -->

    <!-- Video -->
    <div v-if="!isLoading">
      <div class="form" v-for="(m, i) in media" :key="i">
        <h2 style="margin-bottom: 20px">
          Video {{ i + 1 }}
          <a-button
            @click.prevent="removeVideo(i)"
            type="danger"
            icon="delete"
            size="small"
            style="margin-left: 5px"
            v-if="media.length >= 2"
          ></a-button>
        </h2>

        <a-form-item label="Orientation">
          <a-radio-group v-model="m.orientation">
            <a-radio
              v-for="(orientation, i) in mediaOrientations"
              :key="i"
              :value="orientation.orientation"
              >{{ orientation.label }}</a-radio
            >
          </a-radio-group>
        </a-form-item>

        <a-tabs v-model="m.uploadType">
          <a-tab-pane key="add-from-computer" tab="Add From Computer">
            <a-form-item label="Upload video">
              <a-upload
                :multiple="false"
                :file-list="m.mediaFileList"
                :before-upload="(file) => beforeMediaUpload(i, file)"
                :remove="(file) => handleMediaRemove(i, file)"
              >
                <a-button>
                  <a-icon type="upload"></a-icon>
                  Click to Upload
                </a-button>
              </a-upload>
            </a-form-item>
          </a-tab-pane>
          <a-tab-pane key="copy-from-public-link" tab="Copy From Public Link">
            <a-form-item label="Download Video From This Cloud Link">
              <a-input size="large" v-model="m.mediaUrl"></a-input>
            </a-form-item>
          </a-tab-pane>
          <a-tab-pane key="public-mp4-file" tab="Public MP4 File">
            <a-form-item label="Link Directly To This Public .MP4 File">
              <a-input size="large" v-model="m.mediaUrl"></a-input>
            </a-form-item>
          </a-tab-pane>
          <a-tab-pane key="web-embed-link" tab="Web Embed Link">
            <a-form-item label="Link Directly To This Web Embed URL">
              <a-input size="large" v-model="m.mediaUrl"></a-input>
            </a-form-item>
          </a-tab-pane>
        </a-tabs>
      </div>

      <a-button icon="plus" @click.prevent="addVideo">Add Video</a-button>
    </div>
    <!-- / Video -->

    <a-button
      @click.prevent="save"
      class="btn-rounded save-button"
      size="large"
      type="primary"
      >Save</a-button
    >
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import LoadingScreen from "../../../../components/LoadingScreen.vue";
const _ = require("lodash");
import guidanceHelpers from "../../../../helpers/guidance";
import helpVideos from "../../../../api/guidance/help-videos";

export default {
  components: { LoadingScreen },

  data() {
    return {
      helpVideoLocal: {
        displayName: "",
        contextQuote: "",
        scope: 1,
        rank: 0,
      },

      fileList: [], // thumbnail

      media: [
        {
          uploadType: "add-from-computer", // ['add-from-computer', 'copy-from-public-link', 'public-mp4-file', 'web-embed-link'],
          mediaFileList: [], // video upload
          mediaUrl: "",
          orientation: 1,
        },
      ],

      isSaving: false,
    };
  },

  created() {
    if (!this.selectedOrganisation) {
      this.$message.info("Please select an organisation");
      this.$router.push("/admin");
    }
  },

  methods: {
    goBack() {
      this.$router.push("/admin/guidance/help-videos");
    },

    // Thumbnail
    handleRemove(file) {
      const index = this.fileList.indexOf(file);
      const newFileList = this.fileList.slice();
      newFileList.splice(index, 1);
      this.fileList = newFileList;
    },

    beforeUpload(file) {
      const isJpgOrPng =
        file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        this.$message.error("Please upload a JPG or a PNG");
      }
      if (isJpgOrPng) {
        this.fileList = [file];
      }
      return false;
    },

    // Media
    handleMediaRemove(i, file) {
      const index = this.media[i].mediaFileList.indexOf(file);
      const newFileList = this.media[i].mediaFileList.slice();
      newFileList.splice(index, 1);
      this.media[i].mediaFileList = newFileList;
    },

    beforeMediaUpload(i, file) {
      const isMp4 = file.type === "video/mp4" || file.type === "video/x-m4v";
      if (!isMp4) {
        this.$message.error("Please upload an MP4 file");
      }
      if (isMp4) {
        this.media[i].mediaFileList = [file];
      }
      return false;
    },

    // Saving
    getValidationErrors() {
      let errors = [];
      if (this.helpVideoLocal.displayName.trim().length == 0) {
        errors.push("Please provide a video title");
      }

      _.each(this.media, (m, i) => {
        if (m.uploadType == "add-from-computer") {
          if (m.mediaFileList.length == 0) {
            errors.push("Please upload a video for video " + (i + 1));
          }
        } else {
          if (m.mediaUrl.trim().length == 0) {
            errors.push("Please provide a link for a video " + (i + 1));
          }
        }
      });

      return errors;
    },

    addVideo() {
      this.media.push({
        uploadType: "add-from-computer", // ['add-from-computer', 'copy-from-public-link', 'public-mp4-file', 'web-embed-link'],
        mediaFileList: [], // video upload
        mediaUrl: "",
        orientation: 1,
      });
    },

    removeVideo(i) {
      this.media.splice(i, 1);
    },

    async save() {
      let vm = this;

      // Validation
      let validationErrors = this.getValidationErrors();
      if (validationErrors.length) {
        this.$message.error(validationErrors[0]);
        return false;
      }

      vm.isSaving = true;

      let params = {
        ...this.helpVideoLocal,
        ownerId: this.selectedOrganisation.id,
      };

      helpVideos
        .addHelpVideo(vm.tenantId, params)
        .then(async (r) => {
          let helpVideoId = r.data["$v"].id;

          vm.$message.success("Help video created successfully");
          // Upload thumbnail
          if (this.fileList.length) {
            try {
              await helpVideos.addHelpVideoThumbnail(
                vm.tenantId,
                helpVideoId,
                vm.fileList[0]
              );
            } catch (e) {
              console.log(e);
              vm.$message.error("Error uploading thumbnail");
            }
          }

          let mediaLength = vm.media.length;
          for (let i = 0; i < mediaLength; i++) {
            let m = vm.media[i];

            // Upload video if from computer (add-from-computer)
            if (m.uploadType == "add-from-computer" && m.mediaFileList.length) {
              try {
                await helpVideos.attachHelpVideoMediaStreamed(
                  vm.tenantId,
                  helpVideoId,
                  m.mediaFileList[0],
                  m.orientation
                );
              } catch (e) {
                console.log(e);
                vm.$message.error("Error uploading video from computer");
              }
            }

            // Copy from public link (copy-from-public-link)
            if (m.uploadType == "copy-from-public-link") {
              try {
                await helpVideos.attachHelpVideoMediaReference(
                  vm.tenantId,
                  helpVideoId,
                  m.mediaUrl,
                  m.orientation
                );
              } catch (e) {
                console.log(e);
                vm.$message.error("Error copying video from public link");
              }
            }

            // Public MP4 File (public-mp4-file)
            if (m.uploadType == "public-mp4-file") {
              try {
                await helpVideos.attachHelpVideoMediaDirect(
                  vm.tenantId,
                  helpVideoId,
                  m.mediaUrl,
                  m.orientation
                );
              } catch (e) {
                console.log(e);
                vm.$message.error("Error linking to public MP4 file");
              }
            }

            // Web Embed Link (web-embed-link)
            if (m.uploadType == "web-embed-link") {
              try {
                await helpVideos.attachHelpVideoMediaEmbed(
                  vm.tenantId,
                  helpVideoId,
                  m.mediaUrl,
                  m.orientation
                );
              } catch (e) {
                console.log(e);
                vm.$message.error("Error linking to web embed");
              }
            }
          }

          vm.$router.push("/admin/guidance/help-videos");
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  computed: {
    ...mapGetters("missionControl", {
      presences: "presences",
    }),

    ...mapGetters("admin", {
      tenantId: "tenantId",
      selectedOrganisation: "selectedOrganisation",
      isLoading: "isLoading",
    }),

    myPresenceForOrg() {
      return _.find(this.presences, {
        ownerId: this.selectedOrganisation.id,
      });
    },

    ranks() {
      return guidanceHelpers.listRankOptions();
    },

    mediaOrientations() {
      return guidanceHelpers.listMediaOrientations();
    },
  },
};
</script>

<style scoped lang="scss">
.admin-guidance-create-help-video {
  padding-bottom: 40px;

  .loader {
    background: #fff;
    padding: 50px;
    text-align: center;
  }

  .top-row {
    display: flex;
    .left {
      flex-shrink: 1;
    }
    .right {
      padding-left: 20px;
      flex-grow: 1;
    }
  }

  .form {
    background: #fff;
    padding: 20px;
    border-radius: 6px;
    font-weight: 500;
    border: 1px solid #fff;

    margin-bottom: 20px;

    &.last {
      margin-bottom: 0;
    }
  }

  .button-margin-left {
    margin-left: 10px;
  }

  .save-button {
    margin-top: 20px;
  }
}
</style>